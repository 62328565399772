import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import Icon from '@/components/icon';
import PauseIconThin from '@/components/icons/pause-icon-thin';
import PlayIconThin from '@/components/icons/play-icon-thin';
import PlaylistIcon from '@/components/icons/playlist-icon';
import RadioPlayPauseButton from '@/components/play-pause-button/radio-play-pause-button';
import VolumeButton from '@/components/volume-button';
import { selectPlayerCurrentChannel } from '@/store/slices/player';
import { Link } from '@/types/views/generic';

const playPauseIconClasses = 'w-full h-full';

interface ActionButtonsProps {
  displayedStationId: string | null;
  isDisplayedStationPlaying: boolean;
  onPlaylistClick?: () => void;
  playlistLink: Link;
}

const ActionButtons: FC<ActionButtonsProps> = ({
  displayedStationId,
  isDisplayedStationPlaying,
  onPlaylistClick,
  playlistLink,
}) => {
  const currentPlayerChannel = useSelector(selectPlayerCurrentChannel);
  const oldChannelId = currentPlayerChannel?.id;

  const onClick = () => {
    window.dataLayer?.push({
      event: 'station_player_page_play',
      current_channel_id: displayedStationId || '',
      old_channel_id: oldChannelId || '',
    });
  };

  return (
    <div className="flex justify-center items-center">
      <VolumeButton
        className="w-10 h-10 bg-white hover:bg-red text-black"
        iconClassName="w-8 h-8"
      />

      <RadioPlayPauseButton
        isActive={isDisplayedStationPlaying}
        channelId={displayedStationId}
        onClick={onClick}
        playIcon={<PlayIconThin className={playPauseIconClasses} />}
        pauseIcon={<PauseIconThin className={playPauseIconClasses} />}
        className="w-16 h-16 mx-4"
      />

      <Icon
        element={<PlaylistIcon className="w-5 h-5 cursor-pointer" />}
        isCircle
        className="w-10 h-10 bg-white text-black hover:bg-red"
        onClick={onPlaylistClick}
        link={playlistLink}
      />
    </div>
  );
};

export default ActionButtons;
