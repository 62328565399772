import { css } from '@emotion/css';
import cls from 'classnames';
import React, { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';

import NormalButton from '@/components/button/normal';
import CustomLink from '@/components/custom-link';
import Image from '@/components/image';
import getThemedButtonProps from '@/lib/get-themed-button-props';
import { selectGlobalPageTheme } from '@/store/slices/global';
import { ImageAsset, TextLink } from '@/types/views/generic';

const foregroundWrapperClass = css`
  height: 70vh;
`;

interface ForegroundProps {
  foreground?: ImageAsset;
  foregroundMobile?: ImageAsset;
  link?: TextLink;
  isForegroundFullWidth?: boolean;
  showButton: boolean;
}

const Foreground: FC<ForegroundProps> = ({
  foreground,
  foregroundMobile,
  link,
  isForegroundFullWidth,
  showButton,
}) => {
  const pageTheme = useSelector(selectGlobalPageTheme);
  const buttonProps = getThemedButtonProps(pageTheme, {
    eair: 'coral',
    esn: 'mint',
  });

  const renderButton = () =>
    showButton &&
    link &&
    renderWithLinkIfExists(
      <NormalButton
        {...buttonProps}
        text={link.text}
        className="whitespace-nowrap"
      />,
      'absolute z-20 bottom-32 left-1/2 transform -translate-x-1/2',
    );

  const renderWithLinkIfExists = (element: ReactNode, wrapperClass?: string) =>
    link ? (
      <CustomLink {...link} className={wrapperClass}>
        {element}
      </CustomLink>
    ) : (
      <div className={wrapperClass}>{element}</div>
    );

  const renderFullWidthForeground = () => (
    <div className="absolute inset-0">
      {foreground && (
        <Image
          src={foreground?.src}
          objectFit="cover"
          className="hidden z-20 md:block"
        />
      )}

      {foregroundMobile && (
        <Image
          src={foregroundMobile.src}
          objectFit="cover"
          className="md:hidden z-20"
        />
      )}

      {renderButton()}
    </div>
  );

  const renderNormalForeground = () => (
    <div className="absolute z-20 inset-0 flex justify-center items-center">
      <div className="w-full flex flex-col justify-center items-center">
        {foreground &&
          renderWithLinkIfExists(
            <Image src={foreground?.src} objectFit="contain" />,
            cls('hidden md:block w-3/4', foregroundWrapperClass),
          )}

        {foregroundMobile &&
          renderWithLinkIfExists(
            <div className="w-full h-full">
              <Image src={foregroundMobile.src} objectFit="contain" />
            </div>,
            cls('md:hidden w-full aspect aspect-5-8'),
          )}

        {renderButton()}
      </div>
    </div>
  );

  return isForegroundFullWidth
    ? renderFullWidthForeground()
    : renderNormalForeground();
};

export default Foreground;
