import { useRouter } from 'next/router';
import React, { FC } from 'react';

import { ChevronLeftIcon } from '@/components/icons/chevron-icon';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import goToPrevoiusPage from '@/lib/go-to-previous-page';
import usePageTheme from '@/lib/hooks/use-page-theme';
import useVariables from '@/lib/hooks/use-variables';
import { BreadcrumbsEntry } from '@/types/views/generic';

import NaviBackIcon from '../icons/navi-back-icon';
import styles from './styles';

const Breadcrumbs: FC<BreadcrumbsEntry> = ({
  hideOnMobile,
  showBorder = false,
  breadcrumbItems = [],
}) => {
  const router = useRouter();
  const variables = useVariables();
  const { isEventTheme } = usePageTheme();
  const isArticlePage = router.route.includes('/artikel/');

  return (
    <div className={styles.breadcrumbs(isArticlePage)}>
      <div className={styles.container(hideOnMobile)}>
        <div className={styles.wrapper(!isArticlePage && showBorder)}>
          <button
            className={styles.backButton}
            onClick={() => goToPrevoiusPage(router)}
          >
            {isEventTheme ? <NaviBackIcon /> : <ChevronLeftIcon />}

            <span className={styles.backButtonText}>
              {getVariablePlainText(variables['breadcrumbs-back-button']) ||
                'Überblick'}
            </span>
          </button>

          {breadcrumbItems && (
            <div className="flex items-center">
              <div className="w-px h-8 bg-gray-4 mx-4" />

              {breadcrumbItems.map((item) => (
                <div
                  key={item.slug}
                  className="text-pink teair:text-white py-2 px-4 tesn:text-esn-primary"
                >
                  {item.text}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Breadcrumbs;
