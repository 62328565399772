import { FC, ReactNode } from 'react';

import Image from '@/components/image';
import MediaBackground from '@/components/media-background';
import { ImageAsset, MediaAsset } from '@/types/views/generic';

interface SplitForegroundProps {
  image?: ImageAsset;
  backgroundColor?: string;
  text?: string;
  textColor: string;
  showButton: boolean;
  renderButton: () => ReactNode;
  backgroundDesktop?: MediaAsset;
  backgroundMobile?: MediaAsset;
}

const SplitForeground: FC<SplitForegroundProps> = ({
  showButton,
  backgroundColor,
  image,
  text,
  textColor,
  renderButton,
  backgroundDesktop,
  backgroundMobile,
}) => (
  <>
    <div
      className="h-1/2 w-full md:h-full md:w-1/2 flex-none relative"
      style={{
        backgroundColor: `${backgroundColor}`,
      }} // inline style because backgroundColor can be changed in contentful
    >
      {backgroundDesktop && (
        <MediaBackground
          background={backgroundDesktop}
          backgroundMobile={backgroundMobile || backgroundDesktop}
        />
      )}
      <div className="absolute w-full h-3/4 top-0 self-stretch flex-none flex flex-col justify-center items-center p-16 gap-8 z-20">
        {image && (
          <Image src={image.src} objectFit="contain" className="self-stretch" />
        )}

        {text && (
          <span
            className="text-body1 font-normal hidden lg:block text-center"
            style={{ color: `${textColor}` }} // inline style because textColor can be changed in contentful
          >
            {text}
          </span>
        )}

        {showButton && <div className="hidden md:block">{renderButton()}</div>}
      </div>
    </div>
  </>
);

export default SplitForeground;
