import cls from 'classnames';
import { NextPage } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import * as R from 'ramda';
import { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { SidebarAd } from '@/components/advertisement/components/section-ads';
import Breadcrumbs from '@/components/breadcrumbs';
import OneTrustCookieList from '@/components/one-trust/cookie-list';
import PageBackgroundImage from '@/components/page-background-image';
import { FULL_WIDTH_SECTIONS, NO_PADDING_SECTIONS } from '@/lib/constants';
import renderSectionEntry from '@/lib/render-section-entry';
import { selectGlobalPageTheme } from '@/store/slices/global';
import { selectUserProfile } from '@/store/slices/user';
import { PageProps } from '@/types/views/generic';
import { Section } from '@/types/views/sections';

import Layout from '../layout';
import SubNavigation from '../navigation/components/sub-navigation';
import Seo from '../seo';

type LabeledSectionsGroup = ['fullWidth' | 'default', Section[]];

const Page: NextPage<PageProps> = ({
  page,
  navigation,
  themeSettings,
  isPreviewModeEnabled,
  seo,
}) => {
  const pageTheme = useSelector(selectGlobalPageTheme);
  const { asPath } = useRouter();
  const profile = useSelector(selectUserProfile);

  const isEairTheme = pageTheme === 'eair';
  const isArticlePage = asPath.includes('/artikel');
  const isCookieListPage = asPath.includes('/cookie-list');
  const { hasInvertedThemeColor, deactivateAdSlots } = page;

  const hasGallerySection = page.sections.some(
    (section) => section.componentType === 'gallery',
  );

  const fullWidthSections = pageTheme ? FULL_WIDTH_SECTIONS[pageTheme] : [];

  const groupedSections = R.groupWith((previousSection, currentSection) => {
    const A = fullWidthSections.includes(previousSection.componentType);
    const B = fullWidthSections.includes(currentSection.componentType);

    return (A && B) || (!A && !B);
  }, page.sections);

  const groupedSectionsLabeled = groupedSections.map<LabeledSectionsGroup>(
    (group) => [
      fullWidthSections.includes(group[0].componentType)
        ? 'fullWidth'
        : 'default',
      [...group],
    ],
  );

  const { isIndexed = true, theme } = page;
  const isGameTheme = theme === 'game';

  useEffect(() => {
    if (window.dataLayer && profile?.sub) {
      window.dataLayer.push({ user_id: profile.sub });
    }
  }, [asPath, profile?.sub]);

  const renderSections = (sections: Section[], hasFirstSection = false) =>
    sections.map((section, index) => {
      const hasPadding = !NO_PADDING_SECTIONS.includes(section.componentType);
      const isAd = /Ad$/.test(section.componentType);
      const isMobileAd = /^mobile.*?Ad$/.test(section.componentType);
      const isFirstSection = hasFirstSection && index === 0;
      const isArticleTextIntro =
        isArticlePage && section.componentType === 'articleTextIntro';
      const isFirstArticleBody =
        isArticlePage &&
        sections[index - 1]?.componentType === 'articleTextIntro';
      const isArticleImageHeader =
        section.componentType === 'articleImageHeader';
      const isGallerySection = section.componentType === 'gallery';
      const isRelatedArticleSection =
        section.componentType === 'relatedArticles';
      const isTeaserSection = section.componentType === 'teaser';
      const isVideo = section.componentType === 'video';
      const isQuiz = section.componentType === 'quiz';

      const hasCustomBackground =
        isRelatedArticleSection ||
        isArticleImageHeader ||
        isTeaserSection ||
        isGallerySection ||
        hasInvertedThemeColor;

      return (
        <Fragment key={`${section.id}-${index}`}>
          {section.slug && (
            <div id={section.slug} className="relative -top-12" />
          )}

          <section
            className={cls(
              'relative',
              section.layout === 'rich-text' && 'py-6',
              !hasPadding &&
                isFirstSection &&
                'pt-0 md:pt-0 tels:mb-12 tels:md:mb-0',
              hasPadding && isFirstSection && 'teair:pt-0 md:teair:pt-3',
              hasPadding &&
                !isMobileAd &&
                'py-12 md:py-14 tesn:py-8 teair:py-8 tels:py-8',
              isAd && (isMobileAd ? 'md:hidden' : 'hidden md:block'),
              isArticleTextIntro &&
                'pb-0 md:pb-0 teair:pb-0 tesn:pb-0 tels:pb-0',
              isFirstArticleBody &&
                'pt-0 md:pt-0 teair:pt-0  tesn:pt-0 tels:pt-0',
              hasCustomBackground && 'teair:bg-air-deepBlue',
              (isArticlePage || hasInvertedThemeColor) &&
                isVideo &&
                'teair:text-white teair:bg-air-deepBlue',
              isGameTheme && 'game:md:py-8',
              isQuiz && 'pt-0 pb-20 lg:pb-56 game:md:pt-8 game:md:pb-12',
            )}
          >
            {renderSectionEntry({
              hasInvertedThemeColor,
              deactivateAdSlots,
              ...section,
            })}
          </section>
        </Fragment>
      );
    });

  return (
    <>
      {seo && <Seo seo={seo} />}

      {!isIndexed && (
        <Head>
          <meta name="robots" content="noindex" />
          <meta name="googlebot" content="noindex" />
        </Head>
      )}

      <div id="modals" />
      <div id="tooltip" className="relative z-20" />
      <div id="portal-root" />

      <Layout
        {...{
          deactivateAdSlots,
          navigation,
          themeSettings,
          isPreviewModeEnabled,
          hasInvertedThemeColor:
            isEairTheme && hasGallerySection ? true : hasInvertedThemeColor,
          ...(isCookieListPage && {
            className: 'bg-black-light',
          }),
        }}
      >
        {page.backgroundImage && (
          <PageBackgroundImage {...page.backgroundImage} />
        )}

        {page.subNavigation && <SubNavigation subNav={page.subNavigation} />}

        {page.breadcrumbs && <Breadcrumbs {...page.breadcrumbs} />}

        {groupedSectionsLabeled.map(([type, sections], index) => {
          if (isGameTheme) {
            return (
              <div
                className="bg-game-blue-midnight flex justify-center pb-[5rem] md:pb-[20rem]"
                key={index}
              >
                <SidebarAd slotType="SBA_2" position="left" />

                <div
                  className={cls(
                    'relative',
                    isGameTheme
                      ? 'overflow-hidden lg:overflow-visible'
                      : 'overflow-hidden',
                  )}
                >
                  {renderSections(sections, false)}
                </div>

                <SidebarAd slotType="SBA_1" />
              </div>
            );
          }

          return !!page?.deactivateAdSlots || type === 'fullWidth' ? (
            renderSections(sections, index === 0)
          ) : (
            <div
              className={cls(
                'flex justify-center',
                isArticlePage && 'teair:bg-air-deepBlue',
              )}
              key={index}
            >
              <SidebarAd slotType="SBA_2" position="left" />

              <div className="flex flex-col w-full">
                {renderSections(sections, false)}
              </div>

              <SidebarAd slotType="SBA_1" />
            </div>
          );
        })}

        {asPath === '/cookie-list' && <OneTrustCookieList />}
      </Layout>
    </>
  );
};

export default Page;
