import { css } from '@emotion/css';
import cls from 'classnames';
import { FC, useCallback, useState } from 'react';

import { AccordionItemClick } from '@/components/accordion';
import { ArrowRightCircledFilledIcon } from '@/components/icons/arrow-icon';
import RichTextRenderer from '@/components/rich-text-renderer';
import { FaqItemEntry } from '@/types/views/generic';

interface FaqItemProps extends FaqItemEntry {
  isActive: boolean;
  onItemClick: AccordionItemClick;
}

const transitionMaxHeight = css`
  transition: max-height 0.3s ease-in-out;
`;

const FaqItem: FC<FaqItemProps> = ({
  slug,
  title,
  text,
  isActive,
  onItemClick,
}) => {
  const [maxHeight, setMaxHeight] = useState<number>(0);

  const ref = useCallback(
    (node: HTMLDivElement) => {
      if (node) {
        setMaxHeight(isActive ? node.getBoundingClientRect().height : 0);
      }
    },
    [isActive],
  );

  return (
    <div
      className={cls(
        'pb-4 md:pb-5 mb-4 last:mb-0 transition-colors duration-300 rounded-2 teair:bg-air-lila-light ',
        isActive
          ? 'tesn:bg-esn-secondary teds:bg-eds-gray-light tels:bg-els-blue'
          : 'teair:bg-opacity-60 tesn:bg-esn-secondary-dark cursor-pointer teds:bg-eds-black-light tels:bg-els-blue-grey',
      )}
      onClick={() => !isActive && onItemClick(slug)}
    >
      {/* Tricky css way to add an offset for anchor scrolling */}
      <div id={slug} className="relative -top-16 md:-top-24" />

      <div
        className="flex justify-between items-center teair:text-air-deepBlue pt-4 px-4 md:pt-5 md:px-6 cursor-pointer"
        onClick={() => isActive && onItemClick(slug)}
      >
        <h3 className="text-body1 font-semibold mr-4">{title}</h3>

        <ArrowRightCircledFilledIcon
          className={cls(
            'transform transition-transform duration-300 flex-shrink-0',
            isActive ? '-rotate-90' : 'rotate-90',
          )}
          backgroundClassName={cls(
            'transition-colors duration-300 tels:fill-black tesn:fill-esn-primary teair:fill-air-deepBlue',
            isActive ? 'teds:fill-eds-black-light' : 'teds:fill-eds-gray-light',
          )}
          arrowClassName={
            'tesn:fill-esn-secondary-dark teair:fill-air-lila-light fill-air-lila-light'
          }
        />
      </div>
      <div
        className={`overflow-hidden ${transitionMaxHeight}`}
        style={{ maxHeight: `${maxHeight}px` }}
      >
        <div ref={ref} className="text-body2 pt-2 px-4 md:px-6 md:w-11/12">
          <RichTextRenderer body={text} />
        </div>
      </div>
    </div>
  );
};

export default FaqItem;
