import cls from 'classnames';

interface Styles {
  container: (isInfo: boolean, isCentered: boolean) => string;
  title: (isInfo: boolean) => string;
  text: string;
  image: (isInfo: boolean, isCentered: boolean) => string;
}

const styles: Styles = {
  container: (isInfo: boolean, isCentered: boolean): string =>
    cls(
      'flex flex-col',
      isInfo && 'tesn:bg-black',
      isCentered
        ? 'items-center'
        : 'rounded-2 overflow-hidden tesn:bg-esn-secondary-dark',
    ),

  title: (isInfo: boolean): string =>
    cls(
      'text-white text-h5 pb-2 text-center md:text-left',
      isInfo
        ? 'tesn:text-white '
        : 'tesn:text-esn-primary teair:text-air-deepBlue',
    ),

  text: 'text-white teair:text-air-deepBlue tesn:text-white text-body2 text-center md:text-left',

  image: (isInfo: boolean, isCentered: boolean): string =>
    cls(
      'flex pb-6 justify-center',
      isInfo && !isCentered
        ? 'md:justify-start tesn:bg-black'
        : !isCentered && 'tesn:bg-white tesn:pt-4 tesn:px-4 tesn:h-[135px]',
    ),
};

export default styles;
